import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import cornerpink from '../images/cornerpink.png'
import "./Layout.css"
export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenu isAuthenticated={this.props.isAuthenticated} isAdmin={this.props.isAdmin} />
        { this.props.isAuthenticated &&
          <div id="bgContainer">
            <img id="bgImage" src={cornerpink} alt="background" />
          </div>
        }
        <Container className="bodyContainer">
          {this.props.children}
        </Container>
      </div>
    );
  }
}
