import React, { useState, useReducer, useRef } from "react";
import { Row, Col, Alert, Spinner, Button } from "reactstrap";
import { register as registerApi } from "../actions/register";
import "./Register.css";

const initialState = {
  name: "",
  email: "",
  phone: "",
  allergies: "",
  comments: "",
  verifyEmail: "",
  transferNeeds: "",
  songlist: ""
};

const reducer = (state, action) => {
  if (action.type === "reset") {
    return initialState;
  }

  const result = { ...state };
  result[action.type] = action.value;
  return result;
};

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop)

export function Register() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { name, email, phone, allergies, comments, transferNeeds, songlist } = state;
  const [touchedFields, setTouchedFields] = useState({});
  const [saveMessage, setSaveMessage] = useState({});
  const [saving, setsaving] = useState(false);
  const alertRef = useRef(null);

  const handleSubmit = (e) => {
    const registrant = {
      name,
      email,
      phone,
      allergies,
      comments,
      transferNeeds,
      songlist
    };
    setsaving(true);
    registerApi(
      registrant,
      () => {
        setSaveMessage({
          message: "Ilmoittautuminen onnistui - nähdään häissä!",
          color: "success",
          success: true,
        });
        setTouchedFields({});
        setsaving(false);
        dispatch({ type: "reset" });
      },
      () => {
        setSaveMessage({
          message: "Ilmoittautumisessa tapahtui virhe - kokeile uusiksi",
          color: "danger",
          success: false,
        });
        setsaving(false);
        scrollToRef(alertRef)
      }
    );
    e.preventDefault();
  };

  const validateEmail = () => {
    if (!touchedFields["email"]) {
      return null;
    }
    if (email === "") {
      return "Sähköposti on pakollinen tieto";
    } else if (/^.+@.{2,}\..{2,}$/.test(email)) {
      return null;
    }
    return "Virheellinen sähköposti";
  };

  const validatePhone = () => {
    if (!touchedFields["phone"]) {
      return null;
    }
    if (!/^[0-9+\s-]*$/.test(phone)) {
      return "Virheellinen puhelinnumero";
    }
    return null;
  };

  const onChange = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    dispatch({ type: name, value });
    if(event.target.name === 'email'){
      return;
    }
    touchedFields[event.target.name] = "true";
    setTouchedFields(touchedFields);
  };

  const onBlur = (event) => {
    touchedFields[event.target.name] = "true";
    setTouchedFields(touchedFields);
  };

  const renderError = (errorMessage) =>
    errorMessage && (
      <Row>
        <Col></Col>
        <Col>
          <Alert color="danger">{errorMessage}</Alert>
        </Col>
      </Row>
    );

  const nameRequired =
    !touchedFields["name"] || name ? "" : "Nimi on pakollinen tieto";
  const emailError = validateEmail();
  const phoneError = validatePhone();
  const showForm = !saveMessage.success;

  return (
    <div>
      <h3>Ilmoittaudu mukaan juhlimaan viimeistään 9.8.2020</h3>
      <div className="info">
        Jos haluat ilmoittaa samalla kertaa useamman henkilön, erottele nimet ja
        sähköpostit pilkulla.
      </div>
      <div ref={alertRef}>
        {saveMessage.message && <Alert color={saveMessage.color}>{saveMessage.message}</Alert>}
      </div>
      {showForm && (
        <form name="register" onSubmit={handleSubmit}>
          <div style={{paddingBottom:"15px"}}>
            <Row>
              <Col>Nimi / nimet:</Col>
              <Col>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={onChange}
                ></input>
              </Col>
            </Row>
            {renderError(nameRequired)}
            <Row>
              <Col>Sähköposti(t):</Col>
              <Col>
                <input
                  type="text"
                  name="email"
                  value={email}
                  onChange={onChange}
                  onBlur={onBlur}
                ></input>
              </Col>
            </Row>
            {renderError(emailError)}
            <Row>
              <Col>Puhelinnumero:</Col>
              <Col>
                <input
                  type="text"
                  name="phone"
                  value={phone}
                  onChange={onChange}
                ></input>
              </Col>
            </Row>
            {renderError(phoneError)}
            <Row>
              <Col>Tarvitsetko autokyydin kirkolta juhlapaikalle?</Col>
              <Col>
                <textarea
                  name="transferNeeds"
                  value={transferNeeds}
                  onChange={onChange}
                  style={{ height: 100 }}
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col>Erityisruokavalio:</Col>
              <Col>
                <textarea
                  name="allergies"
                  value={allergies}
                  onChange={onChange}
                  style={{ height: 100 }}
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col>Mikä biisi tulisi lisätä juhlien soittolistalle?</Col>
              <Col>
                <textarea
                  name="songlist"
                  value={songlist}
                  onChange={onChange}
                  style={{ height: 100 }}
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col>Terveisesi hääparille:</Col>
              <Col>
                <textarea
                  name="comments"
                  value={comments}
                  onChange={onChange}
                  style={{ height: 100 }}
                ></textarea>
              </Col>
            </Row>
            <Row>
              <Col></Col>
              <Col style={{ textAlign: "right" }}>
                {saving ? (
                  <Spinner color="primary" />
                ) : (
                    <Button color="primary" type="submit">Ilmoittaudu</Button>
                  )}
              </Col>
            </Row>
          </div>
        </form>
      )}
      <div>
        <p>Ota yhteyttä bestmaniin tai kaasoihin jos (kun) haluat pitää häissä puheen,
        kertoa vitsin tai tehdä jotain muuta mahtavaa!</p>
        <ul>
          <li><a href="mailto(kaasotjabestman@lillijapetri2020.fi">kaasotjabestman@lillijapetri2020.fi</a></li>
        </ul>
      </div>
    </div>
  );
}
