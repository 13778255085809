import axios from "axios";
import { ApiRoot } from "./api";
export async function Login(password, successcallback, errorcallback) {
  axios
    .post(`${ApiRoot}authorize`, {
      password: password,
    })
    .then(function (response) {
      successcallback(response.data);
    })
    .catch(function (error) {
      console.log(error);
      errorcallback(error);
    });
}
