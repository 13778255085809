import React, { useState } from "react";
import { Login } from "../actions/authorize";
import { useHistory } from "react-router-dom";
import { Alert, Button } from "reactstrap";
import top from "../images/top.png"
import bottom from "../images/bottom.png"

export function LoginPage({ loginSuccessCallback }) {
  const [error, setError] = useState(null);
  const [password, setPassword] = useState(0);
  const history = useHistory();

  const LoginClick = () => {
    setError(null);
    Login(
      password,
      (auth) => {
        loginSuccessCallback(auth);
        const path = "/tervetuloa"
        history.push(path);
      },
      (data) => {
        if (data.response.status === 404) {
          setError("Sivu ei ole toistaiseksi käytettävissä");
        } else if (data.response.status === 401) {
          setError("Virheellinen salasana");
        } else {
          setError("Tapahtui virhe kirjautuessa");
        }
      }
    );
  };

  return (
      <div className="center" style={{overflow:"none"}}>
        <img style={{objectFit:"scale-down", maxWidth:"100%", width:"500px", marginBottom:"20px"}} src={top} alt="topheader" />
        <div>
          <h2 style={{fontFamily:"'Great Vibes', cursive"}}>Lillin & Petrin Häät 2020</h2>
        </div>
        {error && <Alert color="danger">{error}</Alert>}
        <div style={{display:"flex", flexDirection:"column"}}>
          <span style={{fontStyle:"italic", fontSize:"small", alignSelf:"flex-start"}}>Syötä salasana</span>
          <div>
          <input
            type="text"
            style={{ marginRight: "15px" }}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                LoginClick();
              }
            }}
          />
          <Button color="primary" onClick={LoginClick}>
            Kirjaudu sisään
          </Button>
          </div>
        </div>
        <img src={bottom} alt="bottomheader" style={{objectFit:"scale-down", maxWidth:"100%", width:"500px", marginTop:"20px"}} />
      </div>
  );
}
