import React from "react";
import template from "../images/polaroids/template.png";
import templateBack from "../images/polaroids/template_back.png";

import "./Story.css";

export function Story() {
  const cards = [
    // 2014-2015
    {
      polaroid: "../static/images/polaroid/2015/moidon_bleet.jpg",
      text: "Meidän yhteinen tarina alkoi...",
      backside:
        "...Lady Moonin tanssilattialta Marraskuussa 2014, kun Lilli pyysi Petriä tanssimaan. Tämä kuva tosin on kesältä 2015.",
    },
    {
      polaroid: "../static/images/polaroid/2014/muffinssit.jpg",
      text: "Kun Lilli kävi ensimmäistä kertaa Petrin luona...",
      backside:
        "...Petri hurmasi Lillin kokkaustaidoillaan, tässä kuvassa jälkiruuaksi tehtyjä mustikkamuffinsseja. Tiesittekö, että muffinssien paras leivonta-aika on noin kello 3 aamuyöllä (Petrin mielestä).",
    },
    {
      polaroid: "../static/images/polaroid/2015/eka_ruusu.jpg",
      text: "Petrin ensimmäinen ruusu Lillille",
      backside: (
        <>
          <span>Mutta ei ollut viimeinen ruusu ;)</span>
          <br />
          <span>Nykyään kotimme etu- ja takapihalla kasvavat ruusupensaat sekä muut upeat kukka-asetelmat.</span>
        </>
      ),
    },
    {
      polaroid: "../static/images/polaroid/2015/tallinna.jpg",
      text: "Tallinnan auringon alla",
      backside: "Ensimmäinen yhteinen ulkomaanmatka Tallinnaan kesällä 2015",
    },
    {
      polaroid: "../static/images/polaroid/2015/italiassa.jpg",
      text: "Il mio amore!",
      backside:
        "Ja myöhemmin syksyllä teimme vielä pidemmän reissun Lillin lempimaahan Italiaan. Kohteina Firenze sekä Rooma.",
    },
    {
      polaroid: "../static/images/polaroid/2015/vuosipaivaristeily.jpg",
      text: "Ensimmäinen vuosipäivä vietettiin risteilyllä",
      backside:
        "Yllätys oli melkoinen kun hyttiin saapuessamme huomasimme, että olimme toisiltamme salaa tilanneet täsmälleen samanlaiset hytti-tervehdykset. Skumppa ja mansikat maistuivat!",
    },
    {
      polaroid: "../static/images/polaroid/2015/muuttokuorma.jpg",
      text: "Muuttokuorma kohti Hakametsää",
      backside:
        "Petri lähti edellisenä päivänä auton kanssa Helsinkiin Torkkelinmäelle. Hyvin nukutun yön jälkeen pistettiin asuntoa pakettiin, iltapäivällä Henri ja Ninni saapuivat auttamaan kuorman kasaamisessa. Paluumatkalla Linnatuulesta Henri voitti peliautomaatista päälle 100 euroa. Hyvä tienesti muutosta! Hakametsässä oltiin vasta myöhään illalla.",
    },
    // 2016
    {
      polaroid: "../static/images/polaroid/2016/tuparilahjaviinit.jpg",
      text: "Viiniä, viiniä!",
      backside: (
        <>
          <p>
            Tuparit Hakametsässä - älkää kysykö miten 50 ihmistä mahtuu 70
            neliön asuntoon, mutta niin vain kävi. Rankkasade kaatoi pihalle
            pystytetyn katoksen, mutta muuten kaikki meni hienosti!
          </p>
          <p>Kuvassa tuparilahjaksi saadut viinipullot.</p>
        </>
      ),
    },
    {
      polaroid: "../static/images/polaroid/2016/moidon_pikkujoulut_2.jpg",
      text: "Miami vice!",
      backside:
        "Lähtemässä kohti Moidon pikkujouluja - huomaa Petrin upeat henkselit!",
    },
    {
      polaroid: "../static/images/polaroid/2016/vuosipaiva.jpg",
      text: "Kivaksi perinteeksi on muodostunut...",
      backside:
        "...että jokaisena vuosipäivänä annamme toisillemme pienet lahjat ja kortit. Sekä juhlimme päivää kuohuvalla!",
    },
    {
      polaroid: "../static/images/polaroid/2016/vuodenvaihde.jpg",
      text: "Vuosi vaihtuu!",
      backside:
        "Taustalla Tatun teekkarikummitus! Yhteisten vuosiemme aikana Lillikin on hieman oppinut ymmärtämään teekkarikulttuuria (vain hieman).",
    },
    // 2017
    {
      polaroid: "../static/images/polaroid/2017/sosekeitto.jpg",
      text: "Niin hyvää keittoa!",
      backside: "Petri ei oikein keitoista välitä, mutta tämä Lillin kokkaama kukkakaalisosekeitto vei kielen mennessään. Ekasta suupalasta tuli kylmät väreet ja karvat nousi pystyyn, makunautintona ihan täysi kymppi!",
    },
    {
      polaroid: "../static/images/polaroid/2017/havaiji.jpg",
      text: "Tikivappu!",
      backside:
        "Vuonna 2017 vietettiin vappua Even ja Aksun havaijiteemaisissa bileissä!",
    },
    {
      polaroid: "../static/images/polaroid/2017/budapest.jpg",
      text: "Budapest 2017",
      backside:
        "Reissusta jäi erityisesti mieleen paahtavaa kuumuus ja viinimaistelu Budapestin linnan kellareissa. Kuumuudesta huolimatta kaupunki oli kohteena romanttinen ja seura mitä parhainta.",
    },
    {
      polaroid: "../static/images/polaroid/2017/budapest_rauniobaari.jpg",
      text: "Budapestissä pc-raudan raunioilla",
      backside: "Eksyttiin tähän rauniobaariin, jonka seinillä oli (yhä toimivia) vanhoja pc-romuja. Petri tunnisti näistä useamman.",
    },
    {
      polaroid: "../static/images/polaroid/2017/rolle_hoidossa.jpg",
      text: "Rolle hoidossa",
      backside:
        "Rolle kävi meillä hoidossa useampaankin otteesen. Mahtava koira!",
    },
    {
      polaroid: "../static/images/polaroid/2017/sydan_aamupala.jpg",
      text: "Lillin syntymäpäiväaamupala",
      backside:
        "Petrin bravuurit ovat ehdottomasti rakkaudella valmistetut aamupalat.",
    },
    {
      polaroid: "../static/images/polaroid/2017/tallinna.jpg",
      text: "Tallinna 2017",
      backside:
        "Vuosien varrella Tallinnasta on muodostunut kohde, jossa on aina kiva käydä. Tässä kuvassa löydettiin baarista pieni kalliosyvennykseen upotettu loosi, jossa voitiin olla täysin kaksin ;)",
    },
    {
      polaroid: "../static/images/polaroid/2017/uusi_grilli.jpg",
      text: "Weber in the house",
      backside:
        "Omalla pihalla on erityisen hauskaa grillailla! Ihan vain kaksin tai isommalla porukalla. Huomatkaa erityishieno Tom of Finland -essu, jonka Lilli antoi Petrille syntymäpäivälahjaksi.",
    },
    {
      polaroid: "../static/images/polaroid/2017/antin_laksiaiset.jpg",
      text: "Kauniit & Rohkeat",
      backside:
        "Konttorin takahuone on tullut tutuksi erinäistä juhlista. Konttori on myös paikka, jonne Petri vei Lillin heti ekalla Tampereen reissulla (ja sen jälkeen monen monta kertaa).",
    },
    {
      polaroid: "../static/images/polaroid/2017/moidon_pikkujoulut.jpg",
      text: "Hei me pussataan!",
      backside: "Tiedättekö kuinka monta sananmuunnosta Petri on keksinyt Lillin nimestä? Ei tiedä Petrikään, mutta niitä on monta.",
    },
    // 2017
    {
      polaroid: "../static/images/polaroid/2018/lohjalla_teatteri.jpg",
      text: "Lohjan teatterireissuilla..",
      backside: "..ilta on usein jatkunut karaokebaariin, jossa Lilli laulaa joka kerta Kirkaa. Kuvassa mukana myös kaasot Pia, Tiina ja Laura.",
    },
    {
      polaroid: "../static/images/polaroid/2018/mysteeri.jpg",
      text: "Pakopelikarkurit",
      backside: "Pakopelien pelaaminen on hauskaa ja haastavaa kaksistaan, Jaskan baarista päästiin ulos hetkeä ennen kuin baarin omistaja ehti palata takaisin.",
    },
    {
      polaroid: "../static/images/polaroid/2018/afin_ylpparit.jpg",
      text: "Juhlissa komeena, kuten aina",
      backside: (
        <>
          <img
            alt="paparazzi"
            src="../static/images/polaroid/2018/afin_ylpparit_back.jpg"
            style={{ width: "100%", height: "100%" }}
          />
          <span>Ja sama tilanne paparazzin näkövinkkelistä!</span>
        </>
      ),
    },
    {
      polaroid: "../static/images/polaroid/2018/berliini.jpg",
      text: "Ollaan korkeella",
      backside:
        "Kuva Berliinin tv-tornista 203 metrin korkeudessa. Ylhäällä ei päätä huimannut vaikka alla näkikin melkein koko Berliinin.",
    },
    {
      polaroid: "../static/images/polaroid/2018/porkkanat.jpg",
      text: "Oman kasvimaan tuotantoa",
      backside: (
        <>
          <span>
            Tiesittekö, että saadaan meidän takapihalta:
          </span>
          <ul style={{ textAlign: "left" }}>
            <li>porkkanoita</li>
            <li>perunoita</li>
            <li>ruohosipulia</li>
            <li>persiljaa</li>
            <li>tilliä</li>
            <li>karviaisia</li>
            <li>punaisia & mustia viinimarjoja</li>
          </ul>
          <span>...ja vielä 5 vuotta sitten Lilli ei tiennyt yhtään mitään puutarhahommista. :)</span>
        </>
      ),
    },
    {
      polaroid: "../static/images/polaroid/2018/moidon_kesapaivat.jpg",
      text: "Hei me pussataan taas!",
      backside: "Lillin mielestä Petrissä on parasta se, että Petri nauraa Lillin jutuille (silloinkin, kun ne on tosi huonoja) ;)",
    },
    {
      polaroid: "../static/images/polaroid/2018/turku.jpg",
      text: "Turku 2018",
      backside: "Käytiin Turussa testaamassa miltä tuntuisi juhlistaa vuosipäivää Suomen ulkopuolella. Ja kyllähän se maistui hyvältä! Erityisesti ravintola Kaskis tarjosi sellaiset makumaailmat ettei ole samanlaisia hetkeen tullut vastaan.",
    },
    // 2019
    {
      polaroid: "../static/images/polaroid/2019/hyvinkaa.jpg",
      text: "Hämeenlinna tammikuussa 2019",
      backside: "Arjen keskellä miniviikonloppulomat piristävät. Suunnaksi voi ottaa vaikka Hämeenlinnan, Jyväskylän tai Seinäjoen.",
    },
    {
      polaroid: "../static/images/polaroid/2019/bratislava.jpg",
      text: "Lillin valmistujaismatkalla",
      backside: "Bratislavassa juhlistettiin Lillin valmistumista ja sieltä matka jatkui Wieniin, jossa... yllätys, ylläys... juotiin viiniä.",
    },
    {
      polaroid: "../static/images/polaroid/2019/kihlat.jpg",
      text: "Vaaleanpunaisen taivaan alla 30.6.2019",
      backside: "Oltiin viettämässä iltaa kavereiden kanssa ja erinäisten käänteiden kautta jäätiin paluubussista pois väärällä pysäkillä. Oli kaunis kesäyö, joka pikkuhiljaa jo muuttui aamuksi, kun poimittiin luonnonkukkia ja kysyin Lilliä vaimokseni vaaleanpunaisen taivaan alla.",
    },
    {
      polaroid: "../static/images/polaroid/2019/kylppari_valmis.jpg",
      text: "Kylppäri valmis",
      backside: <><p>Kaikki jotka ovat jaksaneet kuunnella, ovat varmaan kuulleet kuinka kylpyhuoneremontti eteni (eli ei siitä sen enempää). Lopputulos on kuitenkin upea ja nyt kelpaa käydä kylpemässä!</p><p>Kippis sinulle jos jaksoit katsoa nämä kaikki kuvat! Ja toivottavasti nähdään häissä!</p></>,
    },
  ];

  const cardIds = [];
  const renderFlipCard = (card, i) => {
    const rotation = (i % 2 === 0 ? -5 : 5) + 2 * (2 * Math.random() - 1);
    const align = i % 2 === 0 ? "row" : "row-reverse";
    let id = `card${i}`;
    cardIds.push(id);
    return (
      <div
        key={i}
        style={{ flexFlow: align }}
        className="storyboardline"
      >
        <div
          className="flip-card"
          style={{ transform: `rotate(${rotation}deg)` }}
        >
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={template} className="flip-card-template" alt="template" />
              <img src={templateBack} className="flip-card-template" style={{ zIndex: -10 }} alt="templateback" />
              <div className="flip-card-image-container">
                <img
                  id={id}
                  src={card.polaroid}
                  alt={`card${i}`}
                  className="flip-card-image"
                />
              </div>
              <div className="flip-card-text">{card.text}</div>
            </div>
            <div className="flip-card-back"><div>{card.backside}</div></div>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div>
      <div>
        <h3>Kuvia vuosien varrelta</h3>
        <p>Klikkaa tai vie hiiri kuvan päälle ja voit lukea kuvan takaa tarinan!</p>
        <br />
      </div>
      <div className="storyboard">
        {cards.map(renderFlipCard)}
      </div>
    </div>
  );
}
