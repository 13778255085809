import axios from "axios";
import { ApiRoot } from "./api";

const getConfig = () => ({
  headers: { "Authorization": `Bearer ${window.token}` }
});

export async function register(payload, successcallback, errorcallback) {
  
  axios
    .post(`${ApiRoot}register`, payload, getConfig())
    .then(function (response) {
      successcallback(response.data);
    })
    .catch(function (error) {
      errorcallback();
    });
}

export async function getRegistrants(successcallback, errorcallback) {
  return await axios.get(`${ApiRoot}register`, getConfig());
}
