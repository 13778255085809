import React from "react";
import { Link } from "react-router-dom";
import "./Welcome.css"

export function Welcome() {
  return (
    <div style={{textAlign:"center"}}>
      <h3>Tervetuloa hääsivustollemme!</h3>
      <div id="info">
        <p>
          Lämmin kiitos kaikille hääjuhlassamme henkisesti ja fyysisesti mukana olleille! 
        </p>
        <p>
          Hääpäivämme oli juuri niin onnistunut ja täynnä rakkautta kuin toivoimmekin. Kiitos siitä teille kaikille kun olitte osaltanne mukana juhlatunnelmaa luomassa.
        </p>
        <p>
          Hääkuvat ovat nyt nähtävissä <Link to="/kuvat">kuvagalleriassa</Link>. Kuvat on ottanut häävalokuvaajamme <a href="https://kirsimajamaki.com/">Kirsi Majamäki</a>.
        </p>
        <p>
          Kuvia on mahdollista ladata omalle koneelle galleriasta. Ethän muokkaa tai käsittele kuvia, jos julkaiset niitä esimerkiksi sosiaalisessa mediassa.
        </p>
      </div>
    </div>
  );
}

