import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { LoginPage } from './components/LoginPage';
import { Register } from './components/Register';
import { Welcome } from './components/Welcome';
import { Info } from './components/Info';
import { Program } from './components/Program';
import './custom.css'
import { Registrants } from './components/Registrants';
import { Story } from './components/Story';
import { PhotoGallery } from './components/PhotoGallery';

export default class App extends Component {
  static displayName = App.name;
  constructor(){
    super();
    this.state = {};
    //this.state = {authentication: 'je', isAdmin:true};
    var auth = window.sessionStorage.getItem("authorization");
    if(auth){
      auth = JSON.parse(auth);
      this.state = {authentication: auth.token, isAdmin: auth.isAdmin}
    }
  }
  
  onLoginSuccess = authentication => {
    window.sessionStorage.setItem("authorization", JSON.stringify(authentication));
    this.setState({authentication: authentication.token, isAdmin: authentication.isAdmin})
  }

  render () {
    const authentication = this.state && this.state.authentication;
    const isAuthenticated = !!authentication;
    const isAdmin = this.state && this.state.isAdmin && isAuthenticated;
    const loginScreen = <LoginPage loginSuccessCallback={this.onLoginSuccess} />;
    console.log(isAuthenticated)
    return (
      <div  style={{marginBottom:"20px"}}> 
        <Layout isAuthenticated={isAuthenticated} isAdmin={isAdmin}>
          <Switch>
            <Route exact path='/'
              render={() => isAuthenticated ? <Welcome /> : loginScreen}
            />
            <Route path='/ilmoittaudu' render={() => isAuthenticated ? <Register /> : loginScreen} />
            <Route path='/registrants' render={() => isAdmin ? <Registrants /> : loginScreen} />
            <Route path='/tervetuloa' render={() => isAuthenticated ? <Welcome /> : loginScreen} />
            <Route path='/info' render={() => isAuthenticated ? <Info /> : loginScreen} />
            <Route path='/tarina' render={() => isAuthenticated ? <Story /> : loginScreen} />
            <Route path='/ohjelma' render={() => isAuthenticated ? <Program /> : loginScreen} />
            <Route path='/kuvat' render={() => isAuthenticated ? <PhotoGallery /> : loginScreen} />
            <Route>
              <Redirect to="/" />
            </Route>
          </Switch>
        </Layout>
      </div>
    );
  }
}
