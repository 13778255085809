import React from "react";
import "./Info.css"
import VillaSofiaParkkipaikat from '../images/villasofia-parkkipaikat.png'
import VillaSofiaParkkipaikatSmall from '../images/villasofia-parkkipaikat-small.png'
import KirkkoParkkipaikat from '../images/kirkko-parkkipaikat.png'
import KirkkoParkkipaikatSmall from '../images/kirkko-parkkipaikat-small.png'
import ModalImage from "react-modal-image";

export function Info() {
  return (
    <div>
      <h5>Häälahja</h5>
      <div id="gift">
        <p>
          Meille paras lahja on se, että tulet viettämään ikimuistoisen päivän kanssamme.
        </p>
        <p>
          Mikäli haluat muistaa meitä lahjalla, toiveenamme on päästä unelmiemme
          häämatkalle (sitten kun se on mahdollista).
        </p>
        <p style={{ fontWeight: 600 }}>
          Häälahjatili: FI60 5023 0120 0761 63 (IBAN OKOYFIHH)
        </p>
      </div>
      <hr />
      <h5>Hotellit</h5>
      <div>
        Hääpaikka on Tampereen keskustan tuntumassa ja ympäristössä sijaitsee
        monia hyviä hotelleja. Tässä listattu muutamia:
      </div>
      <div>
        <div>
          <div className="hotelTitle">
            <a href="https://www.laplandhotels.com/FI/kaupunkihotellit/tampere/lapland-hotels-tampere" target="_blank" rel="noopener noreferrer">
              Lapland Hotel
            </a>
          </div>
          <div className="hotelInfo">
            <p>
              Tämä lappiteemainen hotelli sijaitsee hääpaikan lähistöllä
              osoitteessa Yliopistonkatu 44. Täällä aamupalalla käydessä voi
              vaikka törmätä tuoreeseen hääpariin! Sisäänkirjautuminen kello 14.00 alkaen ja uloskirjautuminen
              seuraavana päivänä kello 12.00 mennessä. Aamupala viikonloppuisin
              alkaen kello 7.30 ja päättyen kello 11.30.
            </p>
          </div>
        </div>
        <div>
          <div className="hotelTitle">
            <a href="https://hotellikauppi.fi/" target="_blank" rel="noopener noreferrer">Hotelli Kauppi</a>
          </div>
          <div className="hotelInfo">
            <p>
              Vieraanvarainen kaupunkihotelli Tampereella. Aamiainen, ilmainen
              pysäköinti ja sauna uima-allasosastoineen ovat aina mukana
              majoituksessa. Sisäänkirjautuminen kello 14-18 ja uloskirjautuminen seuraavana
              päivänä kello 12 mennessä.
            </p>
          </div>
        </div>
        <div>
          <div className="hotelTitle">
            <a href="https://www.marriott.com/hotels/travel/tmpcy-courtyard-tampere-city/" target="_blank" rel="noopener noreferrer">Marriot Courtyard Tampere</a>
          </div>
          <div className="hotelInfo">
            <p>Upouusi hotelli Tampere-talon kyljessä. Sisäänkirjautuminen kello 15.00 ja uloskirjautuminen seuraavana päivänä kello 12.00 mennessä. Aamupala ei täällä valitettavasti kuulu hintaan, vaan se tulisi ostaa erikseen.</p>
          </div>
        </div>
        <div className="hotelTitle">
          <a href="https://www.sokoshotels.fi/en/tampere/solo-sokos-hotel-torni-tampere" target="_blank" rel="noopener noreferrer">
            Sokos Hotel Torni
          </a>
        </div>
        <div  className="hotelInfo">
          <p>
            Rautatieaseman välittömässä läheisyydessä sijaitseva hotelli, joka
            kohoaa 88 metrin korkeuteen. Täällä näköalat ovat kohdillaan! Sisäänkirjautuminen aikaisintaan kello 15:00 ja uloskirjautuminen
            seuraavana päivänä kello 12 mennessä. Aamiainen kuuluu hintaan ja on tarjolla aamulla kello 7.00 - 12.00. Pysäköinti mahdollista Tullintorin parkkihallissa, josta on suora pääsy hotelliin.
          </p>
        </div>
        <div className="hotelTitle">
          <a href="https://www.scandichotels.fi/hotellit/suomi/tampere/scandic-tampere-station" target="_blank" rel="noopener noreferrer">
            Scandic Tampere Station
          </a>
        </div>
        <div className="hotelInfo">
          <p>
            Modernin trendikäs kaupunkihotelli Tampereen ydinkeskustassa, aivan rautatieaseman ja ostoskatujen vieressä. Hotellissa on oma pysäköintihalli vieraiden käytettävissä.
            Sisäänkirjautuminen aikaisintaan kello 15:00 ja uloskirjautuminen seuraavana päivänä kello 12 mennessä. Aamiainen kuuluu hintaan ja on tarjolla
            kello 7.00 - 10.30.
          </p>
        </div>
        <div className="hotelTitle">
          <a href="https://www.sokoshotels.fi/fi/tampere/sokos-hotel-villa" target="_blank" rel="noopener noreferrer">
            Sokos Hotel Villa
          </a>
        </div>
        <div className="hotelInfo">
          <p>
            Tunnelmallinen ja kodikas hotelli, joka on rakennettu vanhaan viljamakasiiniin. Sijainti keskeisellä paikalla heti Tullintorin välittömässä läheisyydessä.
            Sisäänkirjautuminen kello 15.00 alkaen ja uloskirjautuminen seuraavana päivänä kello 12.00 mennessä. Aamiainen tarjolla kello 7.00 - 12.00. Pysäköinti Tullintorin parkkihallissa, josta on
            myös suora kulku hotellille.
          </p>
        </div>
      </div>
      <hr />
      <h5>Parkkipaikat</h5>
      <div>
      <div>
          <p>Finlaysonin kirkon lähistöllä sopivia parkkipaikkoja löytyy esimerkiksi Polttimonkadun pysäköintialueelta (n. 30 paikkaa) tai Plevnan pysäköintihallista.</p>
          <ModalImage
            small={KirkkoParkkipaikatSmall}
            large={KirkkoParkkipaikat}
            alt='Finlaysonin kirkon parkkipaikat' />
        </div>
        <br/>
        <div>
          <p>Villa Sofian luona heti juhlapaikan edessä on parkkitilaa kolmelle autolle. Tämän lisäksi lähistöllä löytyy muita parkkimahdollisuuksia.</p>
          <p>Juhlapaikan osoite on Kullervonkatu 28:ssa, mutta sisäänkäynti ja parkkipaikat ovat Puu-Tammelan raitin puolella.</p>
          <ModalImage
            small={VillaSofiaParkkipaikatSmall}
            large={VillaSofiaParkkipaikat}
            alt='Villa Sofian parkkipaikat' />
        </div>
      </div>
      <hr/>
      <h5>Yhteystiedot</h5>
      <div>
        <p>Jos tulee ihan mitä tahansa kysyttävää häistä, niin olkaa ihmeessä yhteydessä!</p>
        <ul>
          <li>Petri Sarasvirta, petri.sarasvirta@gmail.com, +358400855654</li>
          <li>Lilli Mäkelä, lilli.makela@gmail.com, +358405109670</li>
          <li>Kaasot & Bestman: <a href="mailto:kaasotjabestman@lillijapetri2020.fi">kaasotjabestman@lillijapetri2020.fi</a>
            <ul>
              <li>Bestman: Henri Sarasvirta</li>
              <li>Kaasot: Laura Stjernberg, Pia Stjernberg, Tiina Pulkkinen</li>
            </ul>
          </li>
        </ul>
      </div>
      <hr/>
      <h5>Häävalokuvat</h5>
      <div>
        <p>Häissämme on ammattivalokuvaaja paikalla varmistamassa ikimuistoisen illan dokumentoinnin. Linkki kuvagalleriaan tulee näille sivuille, ja tästä tiedotamme sähköpostilla kun kuvat on nähtävissä.</p>
      </div>
    </div>
  );
}
