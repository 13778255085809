import React, { useState } from "react";
import { Timeline, TimelineEvent } from "react-event-timeline";
import {
  FaChurch,
  FaCar,
  FaGlassCheers,
  FaBirthdayCake,
  FaMusic,
  FaHotdog,
  FaDoorClosed
} from "react-icons/fa";
import { MdLocalDining } from "react-icons/md";
import "./Program.css";
import { Collapse, Button } from "reactstrap";

const iconScale = { width: "70%", height: "70%" };
const iconColor = "black";
const lineColor = "rgb(245,117,183)";
const bubbleColor = "rgb(255,255,255)";
const bubbleStyle = {
  backgroundColor: bubbleColor,
  borderColor: lineColor,
  width: "40px",
  height: "40px",
  top: "-10px",
  left: "-10px",
};
const lineStyle = { left: "10px", color: lineColor };
const iconStyle = { width: "40px", height: "40px" };
const titleStyle = { fontWeight: "bold" };
const contentStyle = {
  backgroundColor: "rgb(255,252,255)",
  fontSize: "1.1em",
  boxShadow: "rgba(0, 0, 0, 0.1) 0px 6px 8px 0px",
};

export function Program() {
  const [isFinlaysonMapOpen, setIsFinlaysonMapOpen] = useState(false);
  const toggleFinlaysonMap = () => setIsFinlaysonMapOpen(!isFinlaysonMapOpen);

  const [isVillaMapOpen, setIsVillaMapOpen] = useState(false);
  const toggleVillaMap = () => setIsVillaMapOpen(!isVillaMapOpen);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const timelineEvents = [
    {
      time: "16:00",
      title: "Vihkiminen",
      text: (
        <div>
          <div>
            Lilli ja Petri vihitään Finlaysonin kirkossa osoitteessa
            Puuvillatehtaankatu 2, 33210 Tampere
          </div>
          <div>
            <Button color="link" onClick={toggleFinlaysonMap} style={{}}>
              {isFinlaysonMapOpen ? "Sulje sijainti" : "Näytä sijainti"}
            </Button>
            <Collapse isOpen={isFinlaysonMapOpen}>
              <div className="map-responsive">
                <iframe
                  title="Finlaysonin kirkko"
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1903.6354828272533!2d23.7526371!3d61.5023086!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468ed8a8921ace25%3A0x53421396d9b58f1!2sFinlaysonin%20kirkko!5e0!3m2!1sfi!2sfi!4v1589182682786!5m2!1sfi!2sfi"
                  width="600"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </Collapse>
          </div>
        </div>
      ),
      icon: <FaChurch style={iconScale} />,
    },
    {
      time: "16:30",
      title: "Siirtyminen juhlapaikalle",
      text: (
        <div>
          <div>
            Siirtymä juhlapaikalle Villa Sofiaan osoitteeseen Kullervonkatu 28,
            33500 Tampere! Kyyti yhteiskuljetuksella tai omalla kyydityksellä.
          </div>
          <div>
            <Button color="link" onClick={toggleVillaMap} style={{}}>
              {isVillaMapOpen ? "Sulje sijainti" : "Näytä sijainti"}
            </Button>
            <Collapse isOpen={isVillaMapOpen}>
              <div className="map-responsive">
                <iframe
                  title="Villa Sofia"
                  src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d15228.33079975636!2d23.738664319585578!3d61.50384675317048!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x468ed8a8921ace25%3A0x53421396d9b58f1!2sFinlaysonin%20kirkko%2C%20Kuninkaankatu%2C%20Tampere!3m2!1d61.5023086!2d23.7548258!4m5!1s0x468f20aa50032571%3A0xae33e4ff687412c!2sTilaisuus%20Villa%20Sofia%20Oy%2C%20Kullervonkatu%2028%2C%2033500%20Tampere!3m2!1d61.5029833!2d23.7847923!5e0!3m2!1sfi!2sfi!4v1589190977849!5m2!1sfi!2sfi"
                  width="600"
                  height="450"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                ></iframe>
              </div>
            </Collapse>
          </div>
        </div>
      ),
      icon: <FaCar style={iconScale} />,
    },
    {
      time: "17:00",
      title: "Häävastaanotto & maljan nosto",
      text:
        "Nautitaan kuohuvat Villa Sofian pihapiirissä säiden salliessa, muuten sisätiloissa",
      icon: <FaGlassCheers style={iconScale} />,
    },
    {
      time: "17:15",
      title: "Illallinen alkaa",
      text: (
        <div>
          <div>Tarjolla maittava buffet-pöytä.</div>
          <Button color="link" onClick={toggleMenu} style={{}}>
            {isMenuOpen ? "Sulje ruokalista" : "Avaa ruokalista"}
          </Button>
          <Collapse isOpen={isMenuOpen}>
            <div>
              <b>Alkupalat</b>
            </div>
            <ul>
              <li>Peruna-rucola -salaatti (L,G)</li>
              <li>Vihersalaatti ja sitruunavinegraitte (L,G,VE)</li>
              <li>Kasvistäytteinen munakasrulla (L)</li>
              <li>
                Vihreällä pestolla ja luonnonjogurtilla gratinoitua kukkakaalia
                (L,G)
              </li>
              <li>
                Salaatti grillatusta paprikasta, kirsikkatomaatista ja
                mozzarellasta viikunasiirapin kera (L,G)
              </li>
              <li>Provencelainen couscous-salaatti (L,VE)</li>
              <li>Patonki ja tuorejuustolevite (L)</li>
            </ul>
            <div>
              <b>Pääruuat</b>
            </div>
            <ul>
              <li>
                Matalalämmössä kypsennettyä häränrintaa, juusto-perunagratiini
                sekä timjami-uunijuureksia (L,G)
              </li>
              <li>Tofua satay-kastikkeella sekä riisiä (L,G,VE)</li>
            </ul>
            <div>
              <b>Juustot</b>
            </div>
            <ul>
              <li>Manchego</li>
              <li>Cheddar</li>
              <li>Maitojuusto</li>
            </ul>
            <div>
              <b>Juomat</b>
            </div>
            <ul>
              <li>Punaviini: Zensa Primitivo Organico 2019</li>
              <li>Valkoviini: Zenato Pinot Grigio 2019</li>
              <li>Kuohuviini: Pizzolato Biologico Prosecco Extra Dry 2019</li>
              <li>Kotikalja</li>
              <li>Mehu</li>
              <li>Vesi tai vichy</li>
              <li>Olut, siideri tai lonkero</li>
            </ul>
          </Collapse>
        </div>
      ),
      icon: <MdLocalDining style={iconScale} />,
    },
    {
      time: "19:30",
      title: "Hääkakun leikkaus",
      text: (<>
          <p>
            Tarjolla hääkakku, Euran rinkeleitä sekä pehmeitä suklaakeksejä. Kakku tarjoillaan kahvin tai haudutetun teen sekä avecien kera.
          </p>
        </>
      ),
      icon: <FaBirthdayCake style={iconScale} />,
    },
    {
      time: "21:00",
      title: "Häätanssi",
      text: "Morsiuspari pistää jalalla koreasti, jonka jälkeen bileet lähtee käyntiin.",
      icon: <FaMusic style={iconScale} />,
    },
    {
      time: "23:00",
      title: "Iltapala tarjolla",
      text: "Iltapalaa tarjolla alakerrassa",
      icon: <FaHotdog style={iconScale} />,
    },
    {
      time: "02:00",
      title: "Hääpaikan ovet sulkeutuvat",
      text:
        "Hääpari siirtyy viettämään hääyötä, vieraille turvallista kotimatkaa!",
      icon: <FaDoorClosed style={iconScale} />,
    },
  ];

  const renderEvent = (e, i) => (
    <TimelineEvent
      bubbleStyle={bubbleStyle}
      key={i}
      title={`${e.time} - ${e.title}`}
      iconColor={iconColor}
      icon={e.icon}
      iconStyle={iconStyle}
      titleStyle={titleStyle}
      contentStyle={contentStyle}
    >
      {e.text}
    </TimelineEvent>
  );
  return (
    <div>
      <h3>Ohjelma <span style={{fontSize:"0.6em"}}>(alustava)</span></h3>
      <div className="timelineContainer">
        <Timeline
          lineColor={lineColor}
          lineStyle={lineStyle}
          className="timeline"
        >
          {timelineEvents.map(renderEvent)}
        </Timeline>
      </div>
    </div>
  );
}
