import React, { useEffect, useState } from "react";
import { getRegistrants } from "../actions/register";
import { Spinner } from "reactstrap";

const RegistrantCard = (props) => {
  const { registrant } = props;
  return (
    <>
      <td>{registrant.date}</td>
      <td>{registrant.attendeeCount}</td>
      <td>{registrant.name}</td>
      <td>{registrant.email}</td>
      <td>{registrant.phone}</td>
      <td>{registrant.comments}</td>
      <td>{registrant.allergies}</td>
      <td>{registrant.transferNeeds}</td>
      <td>{registrant.songlist}</td>
    </>
  );
};
export function Registrants() {
  const [registrants, setRegistrants] = useState({loading:true, registrants:[]});
  useEffect(() => {
    async function getData() {
      const result = await getRegistrants();
      console.log(result.data);
      setRegistrants({loading:false, registrants: result.data});
    }
    getData();
  }, []);

  const isLoading = registrants.loading;
  return (
    <div>
      <h1>Rekisteröityneet</h1>
      <div>Rekisteröityneet henkilöt</div>
      {isLoading ? (
        <Spinner color="primary"/>
        
      ) : (
        <table id="registrants">
          <thead>
          <tr>
            <th>Ilmoittautumispäivä</th>
            <th>Henkilömäärä</th>
            <th>Nimet</th>
            <th>Sähköpostit</th>
            <th>Puhelin</th>
            <th>Kommentit hääparille</th>
            <th>Allergiat</th>
            <th>Kulkutarpeet</th>
            <th>Biisitoiveet</th>
          </tr>
          </thead>
          <tbody>
          {registrants.registrants.map((r, i) => (
            <tr key={i}>
              <RegistrantCard registrant={r} />
            </tr>
          ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
