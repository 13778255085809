import React from "react";
import {photos} from "./Photos";
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";
import './Photos.css';

export function PhotoGallery() {
  const options = {
    thumbnails: {
      showThumbnails: true,
      thumbnailsAlignment: 'right',
      thumbnailsContainerBackgroundColor: 'transparent',
      thumbnailsContainerPadding: '0',
      thumbnailsGap: '1px',
      thumbnailsOpacity: 0.4,
      thumbnailsPosition: 'bottom',
      thumbnailsSize: ['100px', '80px']
    },
    caption: {
      showCaption: true
    }
  };
  return (
    <>
      <div className="copyright">
        Kuvia on mahdollista ladata omalle koneelle galleriasta. Ethän muokkaa tai käsittele kuvia, jos julkaiset niitä esimerkiksi sosiaalisessa mediassa.
      </div>
      <a href="https://kirsimajamaki.com/" className="copyright">(c) Kirsi Majamäki Photography</a>
      <SimpleReactLightbox>
        <SRLWrapper options={options}>
            <div className="photos">
              {photos}
            </div>
        </SRLWrapper>
      </SimpleReactLightbox>
    </>
  );
}
